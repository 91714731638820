import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./shape.scss";
import { styleToObjResponsive } from "../../utils/functions";

const ShapeComponent = (props) => {
  const { data } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let image = null;
  if (!desktop && data.image && data.image.image) {
    image = data.image.image;
  }
  if (desktop && data.image && data.image.desktop_image) {
    image = data.image.desktop_image;
  }

  return (
    <div className={`component-shape component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" style={styleToObjResponsive(data.svg.style, data.svg.style_desktop, desktop)}>
        <polygon fill={data.shape.color} points={data.shape.points} style={styleToObjResponsive(data.shape.style, data.shape.style_desktop, desktop)} />
      </svg>

      {image && <img src={image} alt={data.image.alt} style={styleToObjResponsive(data.image.style, data.image.style_desktop, desktop)} />}
    </div>
  );
}

export default ShapeComponent;