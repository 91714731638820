import React from 'react';
import Flickity from 'react-flickity-component'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./carousel.scss";
import "./flickity.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

const options = {
  cellAlign: 'left',
  contain: true,
  resize: true,
  wrapAround: true,
  freeScroll: true,
  pageDots: false,
  autoPlay: true,
  //autoPlay: 100000
};

const Carousel = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  const flkty = React.useRef(null);
  React.useEffect(() => {
    let timeoutID = setTimeout(() => {
      if (flkty.current) {
        flkty.current.next();
      }
    }, 50);
    return () => {
      clearTimeout(timeoutID);
    }
  }, []);

  if (!data.cells || !data.cells.items || !data.cells.items.length) return null;

  const title = getText(data, `title.text.${lang}`, "");

  return (
    <div className={`component-carousel component`}>
      {title && data.title && <div className="carousel-title" style={styleToObjResponsive(data.title.style, data.title.style_desktop, desktop)}>{title}</div>}

      <Flickity className={`carousel-wrapper`}
        style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
        options={options}
        reloadOnUpdate
        flickityRef={(carouselRef) => {
          flkty.current = carouselRef;
        }}
      >
        {data.cells && data.cells.items.map((el, i) => {
          let img = el.image;
          if (desktop) img = el.desktop_image;

          return <div className="carousel-cell" key={`cell_${i}`}>
            <div className="cell-content">
              <a href={el.url} target="_blank" rel="noopener noreferrer">
                <img src={img} alt={el.alt} style={styleToObjResponsive(el.style, el.style_desktop, desktop)} />
              </a>
            </div>
          </div>;
        })}
      </Flickity>
    </div>
  );
}

export default Carousel;