/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import axios from "axios";

import { appConstants } from "../constants";
import { appReceivedConfig } from '../actions/app';
import endpoints from "../../api/endpoints";

function axiosObject(url) {
  return axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw err;
    });
}

function* fetchConfigSaga() {
  try {
    let data = yield call(axiosObject, endpoints.configApiUrl);
    if (data) {
      yield put(appReceivedConfig(data));
    }
  } catch (e) {
    console.log("[fetchConfigSaga] err", e);
  }
}


export default function* watchLottoEvents() {
  yield takeEvery(appConstants.INITIALIZE, fetchConfigSaga);
  yield takeEvery(appConstants.RELOAD_CONFIG, fetchConfigSaga);
}