import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./image.scss";
import { styleToObjResponsive } from "../../utils/functions";

const ImageComponent = (props) => {
  const { data } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let image = null;
  if (!desktop && data.image && data.image.image) {
    image = data.image.image;
  }
  if (desktop && data.image && data.image.desktop_image) {
    image = data.image.desktop_image;
  }

  if (!image) return null;

  return (
    <div className={`component-shape component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      {image && <img src={image} alt={data.image.alt} style={styleToObjResponsive(data.image.style, data.image.style_desktop, desktop)} />}
    </div>
  );
}

export default ImageComponent;