import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import "./result-dialog.scss";
import { getText } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const imgPath = (fileName) => {
  return `https://h6v9r7t5.stackpathcdn.com/winner/wheel-assets/${fileName}`;
}

const dialogBgStyle = {
  backgroundImage: `url(${imgPath("dialog-bg.png")})`
}

const ResultsDialog = (props) => {
  const { open, onClose, data, lang } = props;

  const title = getText(data, `product_title.text.${lang}`, "");
  const subtitle = getText(data, `prize_title.text.${lang}`, "");
  const value = getText(data, `prize_value.text.${lang}`, "");
  const units = getText(data, `prize_units.text.${lang}`, "");
  const logo = data && data.popup_logo ? data.popup_logo : imgPath('winner-logo.svg');

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="wheel-result-dialog"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className="result-dialog-body" style={dialogBgStyle}>
        <div className="results-dialog-logo">
          <img src={logo} alt="" />
        </div>
        <div className="result-dialog-title">
          AI CASTIGAT!
        </div>
        <div className={`wrapper-info free`}>
          <div className="center-piece">
            <div className="top-piece"></div>
            <div className="product">
              <div className="product-title">
                {title}
              </div>
              <div className="product-subtitle">
                {subtitle}
              </div>
            </div>
            <div className="bottom-piece"></div>
          </div>
          <div className="outer-wrapper">
            <div id="full-wrapper">
              <div className="value-piece" id="results-value">
                {value}
              </div>
              <div className="value-name">
                {units}
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-column">
          <a href={data && data.cta_button && data.cta_button.url ? data.cta_button.url : ''} className="highlighted" onClick={onClose}>
            <div className="middle">
              <div className="inner">
                <div className="txt">
                  Activeaza premiu
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Dialog>
  );
};


export default ResultsDialog;
