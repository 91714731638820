import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./footer.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

import OnjnImg from "./assets/onjn.png";
import AgeImage from "./assets/18years.png";

const Footer = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let onjn_image = OnjnImg;
  if (!desktop && data.onjn_image.image) {
    onjn_image = data.onjn_image.image;
  }
  if (desktop && data.onjn_image.desktop_image) {
    onjn_image = data.onjn_image.desktop_image;
  }

  let age_restriction_image = AgeImage;
  if (!desktop && data.age_restriction_image.image) {
    age_restriction_image = data.age_restriction_image.image;
  }
  if (desktop && data.age_restriction_image.desktop_image) {
    age_restriction_image = data.age_restriction_image.desktop_image;
  }

  return (
    <div className={`component-footer component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className="footerContent">
        <div className="footerTop">
          <div className="footerImages">
            <img src={onjn_image} alt={data.onjn_image.alt} style={styleToObjResponsive(data.onjn_image.style, data.onjn_image.style_desktop, desktop)} />
            <img src={age_restriction_image} alt={data.age_restriction_image.alt} style={styleToObjResponsive(data.age_restriction_image.style, data.age_restriction_image.style_desktop, desktop)} />
          </div>
          <p className="footerDescription" style={styleToObjResponsive(data.license.style, data.license.style_desktop, desktop)}>
            {getText(data, `license.text.${lang}`, "")}
          </p>
        </div>
        <p
          className="footerText rich-text"
          style={styleToObjResponsive(data.text.style, data.text.style_desktop, desktop)}
          dangerouslySetInnerHTML={{ __html: getText(data, `text.text.${lang}`, "") }}>
        </p>
      </div>
    </div>
  );
}

export default Footer;