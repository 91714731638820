import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./main_banner_1.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";
import DefaultLogo from "./assets/logo.png";

const MainBannerComponent = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  const banner_title_1 = getText(data, `banner_title_1.text.${lang}`, "");
  const banner_subtitle_1 = getText(data, `banner_subtitle_1.text.${lang}`, "");
  const banner_title_2 = getText(data, `banner_title_2.text.${lang}`, "");
  const banner_subtitle_2 = getText(data, `banner_subtitle_2.text.${lang}`, "");

  let logo = DefaultLogo;
  if (!desktop && data.logo.image) {
    logo = data.logo.image;
  }
  if (desktop && data.logo.desktop_image) {
    logo = data.logo.desktop_image;
  }

  const bgStyle = styleToObjResponsive(data.bg_image.style, data.bg_image.style_desktop, desktop);
  if (!desktop && data.bg_image.image) {
    bgStyle.backgroundImage = `url("${data.bg_image.image}")`;
  }
  if (desktop && data.bg_image.desktop_image) {
    bgStyle.backgroundImage = `url("${data.bg_image.desktop_image}")`;
  }

  React.useEffect(() => {
    const header = document.getElementById("header")
    let topOfHeader = header.offsetTop;
    function fixedHeader() {
      if (window.pageYOffset > topOfHeader) {
        header.classList.add('fixedHeader');
      } else {
        header.classList.remove('fixedHeader');
      }
    }
    window.addEventListener('scroll', fixedHeader);

    return () => {
      window.removeEventListener('scroll', fixedHeader);
    }
  }, []);

  return (
    <div className={`component-main_banner_1 component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className="after" style={bgStyle}></div>
      <div className="bannerContent">
        <div className="cover"></div>
        <div className="topBanner">
          <div className="header" id="header">
            <a href={data.logo.url} className="logo">
              <img src={logo} style={styleToObjResponsive(data.logo.style, data.logo.style_desktop, desktop)} alt="Winner" className="logoImg" />
            </a>
            <div className="topButtons">
              <a href={data.register_button.url} style={styleToObjResponsive(data.register_button.style, data.register_button.style_desktop, desktop)} className="button primary" >{getText(data, `register_button.text.${lang}`, "")}</a>
              <a href={data.account_button.url} style={styleToObjResponsive(data.account_button.style, data.account_button.style_desktop, desktop)} className="button secondary" >{getText(data, `account_button.text.${lang}`, "")}</a>
            </div>
          </div>
          <h1 className="bannerTitle m150" style={styleToObjResponsive(data.banner_title_1.style, data.banner_title_1.style_desktop, desktop)} >{banner_title_1 ? banner_title_1 : <span>&nbsp;</span>}</h1>
          <h3 className="bannerSubTitle" style={styleToObjResponsive(data.banner_subtitle_1.style, data.banner_subtitle_1.style_desktop, desktop)} >{banner_subtitle_1 ? banner_subtitle_1 : <span>&nbsp;</span>}</h3>
          <h1 className="bannerTitle" style={styleToObjResponsive(data.banner_title_2.style, data.banner_title_2.style_desktop, desktop)} >{banner_title_2 ? banner_title_2 : <span>&nbsp;</span>}</h1>
          <h3 className="bannerSubTitle last" style={styleToObjResponsive(data.banner_subtitle_2.style, data.banner_subtitle_2.style_desktop, desktop)} >{banner_subtitle_2 ? banner_subtitle_2 : <span>&nbsp;</span>}</h3>
          <a href={data.cta_button.url} style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)} className="button gradient" >{getText(data, `cta_button.text.${lang}`, "")}</a>
        </div>
      </div>
    </div>
  );
}

export default MainBannerComponent;