import React from 'react';
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import './App.scss';
import { appInitialize } from "./store/actions/app";
import Home from './pages/Home';
import LadingPages from './pages/LandingPages';

function App(props) {

  React.useEffect(() => {
    props.appInitialize();
  });

  return (
    <div className="App">
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='*' component={LadingPages} />
      </Switch>
    </div>
  );
}

const actionCreators = {
  appInitialize
};

export default connect(null, actionCreators)(App);
