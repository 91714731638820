import React from 'react';

const Home = (props) => {

  React.useEffect(() => {
    window.location.assign("https://www.winner.ro");
  });

  return (
    <div>
      Home Page
    </div>
  );
}

export default Home;