const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const styleToObj = (style) => {
  const ret = {};

  if (!style) return ret;
  if (typeof style !== "string") return ret;

  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

export const styleToObjResponsive = (mobileStyle, desktopStyle, isDesktop) => {
  const ret = {};

  let style = mobileStyle;
  if (isDesktop) style = desktopStyle;

  if (!style) return ret;
  if (typeof style !== "string") return ret;

  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

export const getText = (data, path, def) => {
  const elements = path.split(".");
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf("[") > -1) { // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== "undefined") {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== "undefined") {
        if ((element === "ro" || element === "en")) { // there is no translation for that language check the remaining
          let txtLang = tmp[element];
          if (!txtLang) {
            if (element === "ro") {
              txtLang = tmp["en"];
            } else if (element === "en") {
              txtLang = tmp["ro"];
            }
          }
          tmp = txtLang;
        } else {
          tmp = tmp[element];
        }
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
}

export const getStyle = (data, path, def) => {
  const elements = path.split(".");
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf("[")) { // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== "undefined") {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== "undefined") {
        tmp = tmp[element];
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
}

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

function iOS() {
  return [
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const config = {
  platformType: deviceType() === "desktop" ? 1 : 2,
}

let isDesktop = config.platformType === 1 ? true : false;
let isMobile = config.platformType === 2 && !config.cordova ? true : false;
let isIos = isMobile && iOS() && !config.cordova ? true : false;
let isAndroid = isMobile && !isIos && !config.cordova ? true : false;
let isNativeApp = config.cordova ? true : false;
let isNativeIosApp = config.native_platform === "ios";
let isNativeAndroidApp = config.native_platform === "android";

export const checkItemAvailability = (item) => {
  if (!item || !(item.data && item.data.active)) return false;

  if (item && item.data && item.data.timeEnabled) {
    const currentTime = Math.floor(+new Date() / 1000);

    if (!(currentTime >= item.data.startTime && currentTime <= item.data.endTime)) {
      return false;
    }
  }

  if (item && item.data && item.data.where) {
    let thisPlatform = false;

    item.data.where.forEach(platform => {
      switch (platform) {
        case "all":
          thisPlatform = true;
          break;
        case "desktop":
          if (isDesktop) thisPlatform = true;
          break
        case "mobile":
          if (isMobile) thisPlatform = true;
          break
        case "mobile-android":
          if (isAndroid) thisPlatform = true;
          break
        case "mobile-ios":
          if (isIos) thisPlatform = true;
          break
        case "native-app":
          if (isNativeApp) thisPlatform = true;
          break
        case "native-app-ios":
          if (isNativeIosApp) thisPlatform = true;
          break
        case "native-app-android":
          if (isNativeAndroidApp) thisPlatform = true;
          break
        default:
        /* noop */
      }
    });

    if (!thisPlatform) return false;
  }

  return true;
}

export const checkComponentAvailability = (component) => {
  if (component) {
    if (!component.active) return false;

    if (component.timeEnabled) {
      const currentTime = Math.floor(+new Date() / 1000);

      if (!(currentTime >= component.startTime && currentTime <= component.endTime)) {
        return false;
      }
    }

    if (component.where) {
      let thisPlatform = false;

      component.where.forEach(platform => {
        switch (platform) {
          case "all":
            thisPlatform = true;
            break;
          case "desktop":
            if (isDesktop) thisPlatform = true;
            break
          case "mobile":
            if (isMobile) thisPlatform = true;
            break
          case "mobile-android":
            if (isAndroid) thisPlatform = true;
            break
          case "mobile-ios":
            if (isIos) thisPlatform = true;
            break
          case "native-app":
            if (isNativeApp) thisPlatform = true;
            break
          case "native-app-ios":
            if (isNativeIosApp) thisPlatform = true;
            break
          case "native-app-android":
            if (isNativeAndroidApp) thisPlatform = true;
            break
          default:
          /* noop */
        }
      });

      if (!thisPlatform) return false;
    }
  }

  return true;
}
