const stagingEndpoints = {
  betsApiUrl: "https://test-micros1.play-online.com",
  configApiUrl: `https://test-micros1.play-online.com/landingpagesapi/config/${process.env.REACT_APP_CLIENT_ID}`,
};

const productionEndpoints = {
  betsApiUrl: "https://micros1-ro.play-online.com",
  configApiUrl: `https://micros1-ro.play-online.com/landingpagesapi/config/${process.env.REACT_APP_CLIENT_ID}`,
};

let endpoints;

endpoints = productionEndpoints;
if ((process && process.env.NODE_ENV === "development") || (process && process.env.REACT_APP_ENVIRONMENT === "staging")) {
  endpoints = stagingEndpoints;
}

export default endpoints;