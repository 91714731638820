import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import anime from 'animejs/lib/anime.es.js';

import "./wheel_banner.scss";
import { styleToObj, styleToObjResponsive, getText } from "../../utils/functions";
import DefaultLogo from "./assets/logo.png";
import ResultDialog from "./result-dialog";

let img_id = 0;

const WheelBannerComponent = (props) => {
  const { data, lang } = props;

  const [done, setDone] = React.useState(false);

  img_id += 1;

  const desktop = useMediaQuery('(min-width: 813px)');

  const banner_title_1 = getText(data, `banner_title_1.text.${lang}`, "");
  const banner_subtitle_1 = getText(data, `banner_subtitle_1.text.${lang}`, "");
  const banner_title_2 = getText(data, `banner_title_2.text.${lang}`, "");
  const banner_subtitle_2 = getText(data, `banner_subtitle_2.text.${lang}`, "");

  let logo = DefaultLogo;
  if (!desktop && data.logo.image) {
    logo = data.logo.image;
  }
  if (desktop && data.logo.desktop_image) {
    logo = data.logo.desktop_image;
  }

  const bgStyle = styleToObjResponsive(data.bg_image.style, data.bg_image.style_desktop, desktop);
  if (!desktop && data.bg_image.image) {
    bgStyle.backgroundImage = `url("${data.bg_image.image}")`;
  }
  if (desktop && data.bg_image.desktop_image) {
    bgStyle.backgroundImage = `url("${data.bg_image.desktop_image}")`;
  }

  const rightImageStyle = styleToObj(data.right_image.style);
  if (data.right_image.image) {
    rightImageStyle.backgroundImage = `url("${data.right_image.image}")`;
  }

  const wheel1Ref = React.useRef(null);
  const wheel2Ref = React.useRef(null);
  const wheel3Ref = React.useRef(null);

  React.useEffect(() => {
    wheel1Ref.current = anime({
      targets: '.wheel_1',
      rotate: '1turn',
      loop: true,
      easing: 'linear',
      duration: 14000,
    });

    wheel2Ref.current = anime({
      targets: '.wheel_2',
      rotate: '1turn',
      loop: true,
      easing: 'linear',
      duration: 9000,
    });

    wheel3Ref.current = anime({
      targets: '.wheel_3',
      rotate: '1turn',
      loop: true,
      easing: 'linear',
      duration: 6000,
    });
  }, []);

  const handleButton = (e) => {
    e.preventDefault();
    e.stopPropagation();

    wheel1Ref.current.pause();
    wheel2Ref.current.pause();
    wheel3Ref.current.pause();

    anime({
      targets: '.wheel_1',
      rotate: '3turn',
      easing: 'easeOutQuart',
      duration: 3000,
    });

    anime({
      targets: '.wheel_2',
      rotate: '3turn',
      easing: 'easeOutQuart',
      duration: 5000,
    });

    anime({
      targets: '.wheel_3',
      rotate: '4turn',
      easing: 'easeOutQuart',
      duration: 7000,
      complete: function (anim) {
        setDone(true);
      }
    });
  };

  let wheel1Image = "https://t6n3v3q4.stackpathcdn.com/wheel_1-1646308701939.png";
  if (data.wheel1_image) {
    wheel1Image = data.wheel1_image;
  }

  let wheel2Image = "https://t6n3v3q4.stackpathcdn.com/wheel_2-1646308709558.png";
  if (data.wheel2_image) {
    wheel2Image = data.wheel2_image;
  }

  let wheel3Image = "https://t6n3v3q4.stackpathcdn.com/wheel_3-1646308714834.png";
  if (data.wheel3_image) {
    wheel3Image = data.wheel3_image;
  }

  React.useEffect(() => {
    const header = document.getElementById("header")
    let topOfHeader = header.offsetTop;
    function fixedHeader() {
      if (window.pageYOffset > topOfHeader) {
        header.classList.add('fixedHeader');
      } else {
        header.classList.remove('fixedHeader');
      }
    }
    window.addEventListener('scroll', fixedHeader);

    return () => {
      window.removeEventListener('scroll', fixedHeader);
    }
  }, []);

  return (
    <div className={`component-wheel-banner component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className="after" style={bgStyle}></div>
      <div className="bannerContent">
        <div className="cover"></div>
        <div className="wheelArea mobile">
          <img src="https://t6n3v3q4.stackpathcdn.com/hud-1646308674755.png" className="hud" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/arrow_left-1646308653195.png" className="arrow_left" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/arrow_center-1646308646198.png" className="arrow_center" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/arrow_right-1646308659585.png" className="arrow_right" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/effect-1646308668173.png" className="effect" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/wheel_bg_decorations-1646308720328.png" className="wheel_bg_decorations" alt="" />
          <img src="https://t6n3v3q4.stackpathcdn.com/wheel_pin-1646308725858.png" className="wheel_pin" alt="" />
          <img src={wheel1Image} className="wheel_1" alt="" />
          <img src={wheel2Image} className="wheel_2" alt="" />
          <div className="wheelShadow"></div>
          <img src={wheel3Image} className="wheel_3" alt="" />
        </div>
        <div className="topBanner hd">
          <div className="header" id="header">
            <a href={data.logo.url} className="logo">
              <img src={logo} style={styleToObjResponsive(data.logo.style, data.logo.style_desktop, desktop)} alt="Winner" className="logoImg" />
            </a>
            <div className="topButtons">
              <a href={data.register_button.url} style={styleToObjResponsive(data.register_button.style, data.register_button.style_desktop, desktop)} className="button primary" >{getText(data, `register_button.text.${lang}`, "NOT DEFINED")}</a>
              <a href={data.account_button.url} style={styleToObjResponsive(data.account_button.style, data.account_button.style_desktop, desktop)} className="button secondary" >{getText(data, `account_button.text.${lang}`, "NOT DEFINED")}</a>
            </div>
          </div>
        </div>
        <div className="contentWrapper">
          <div className="topBanner cnt">
            <h1 className="bannerTitle m150" style={styleToObjResponsive(data.banner_title_1.style, data.banner_title_1.style_desktop, desktop)} >{banner_title_1 ? banner_title_1 : <span>&nbsp;</span>}</h1>
            <h3 className="bannerSubTitle" style={styleToObjResponsive(data.banner_subtitle_1.style, data.banner_subtitle_1.style_desktop, desktop)} >{banner_subtitle_1 ? banner_subtitle_1 : <span>&nbsp;</span>}</h3>
            <h1 className="bannerTitle" style={styleToObjResponsive(data.banner_title_2.style, data.banner_title_2.style_desktop, desktop)} >{banner_title_2 ? banner_title_2 : <span>&nbsp;</span>}</h1>
            <h3 className="bannerSubTitle last" style={styleToObjResponsive(data.banner_subtitle_2.style, data.banner_subtitle_2.style_desktop, desktop)} >{banner_subtitle_2 ? banner_subtitle_2 : <span>&nbsp;</span>}</h3>
            <div onClick={handleButton} style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)} className="button gradient" >{getText(data, `cta_button.text.${lang}`, "NOT DEFINED")}</div>
          </div>
          <div className="wheelArea">
            <img src="https://t6n3v3q4.stackpathcdn.com/hud-1646308674755.png" className="hud" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/arrow_left-1646308653195.png" className="arrow_left" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/arrow_center-1646308646198.png" className="arrow_center" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/arrow_right-1646308659585.png" className="arrow_right" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/effect-1646308668173.png" className="effect" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/wheel_bg_decorations-1646308720328.png" className="wheel_bg_decorations" alt="" />
            <img src="https://t6n3v3q4.stackpathcdn.com/wheel_pin-1646308725858.png" className="wheel_pin" alt="" />
            <img src={wheel1Image} className="wheel_1" alt="" />
            <img src={wheel2Image} className="wheel_2" alt="" />
            <div className="wheelShadow"></div>
            <img src={wheel3Image} className="wheel_3" alt="" />
          </div>
          <div className="buttonsArea" style={rightImageStyle}></div>
        </div>
      </div>
      {done && <ResultDialog open={true} onClose={() => { setDone(false) }} data={data} lang={lang} />}
    </div>
  );
}

export default WheelBannerComponent;