import { all, call } from "redux-saga/effects";

import watchWS from "./ws";
import watchApp from "./app";

export function* lpSaga() {
  yield all([
    call(watchWS),
    call(watchApp),
  ]);
}