import { combineReducers } from "redux";

import appReducer from "./app";

export const lpRootReducer = combineReducers({
  app: appReducer,
});

const rootReducer = combineReducers({
  lp: lpRootReducer
});

export default rootReducer;