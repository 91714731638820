import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./generic_block_1.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

import Block1Img from "./assets/block1.png";

import GoogleStore from "./assets/googleplay.png";
import AppleStore from "./assets/appstore.png";
import HuaweiStore from "./assets/appgallery.png";

const GenericBlockComponent = (props) => {
  const { data, lang } = props;

  const desktop = useMediaQuery('(min-width: 813px)');

  let image = Block1Img;
  if (data.image) {
    if (!desktop && data.image.image) {
      image = data.image.image;
    }
    if (desktop && data.image.desktop_image) {
      image = data.image.desktop_image;
    }
  }

  return (
    <div className={`component-generic_block_1 component ${data.image.reverse ? 'reverse' : ''}`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <div className="blockContainer">
        <div className="blockImage" style={styleToObjResponsive(data.image.style, data.image.style_desktop, desktop)}>
          <img src={image} alt={data.image.alt} />
        </div>
        <div className="blockContent">
          <h2 className="blockTitle" style={styleToObjResponsive(data.title.style, data.title.style_desktop, desktop)}>{getText(data, `title.text.${lang}`, "")}</h2>
          <p className="blockText rich-text" style={styleToObjResponsive(data.text.style, data.text.style_desktop, desktop)} dangerouslySetInnerHTML={{ __html: getText(data, `text.text.${lang}`, "") }}></p>
          {data.show_store_buttons && <div className="storeButtons">
            {data.apple.enabled && <a href={data.apple.url} style={styleToObjResponsive(data.apple.style, data.apple.style_desktop, desktop)}><img src={data.apple.image ? data.apple.image : AppleStore} alt={data.apple.alt} /></a>}
            {data.huawei.enabled && <a href={data.huawei.url} style={styleToObjResponsive(data.huawei.style, data.huawei.style_desktop, desktop)}><img src={data.huawei.image ? data.huawei.image : HuaweiStore} alt={data.huawei.alt} /></a>}
            {data.google.enabled && <a href={data.google.url} style={styleToObjResponsive(data.google.style, data.google.style_desktop, desktop)}><img src={data.google.image ? data.google.image : GoogleStore} alt={data.google.alt} /></a>}
          </div>}
          {data.cta_button.enabled &&
            <a href={data.cta_button.url} rel="noopener noreferrer" className="button gradient" style={styleToObjResponsive(data.cta_button.style, data.cta_button.style_desktop, desktop)}>{getText(data, `cta_button.text.${lang}`, "")}</a>
          }
        </div>
      </div>
    </div>
  );
}

export default GenericBlockComponent;