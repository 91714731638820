import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import "./terms_and_conditions_1.scss";
import { styleToObjResponsive, getText } from "../../utils/functions";

import { ReactComponent as IconMinus } from "./assets/icon-minus.svg";
import { ReactComponent as IconPlus } from "./assets/icon-plus.svg";

const TermsAndConditionsComponent = (props) => {
  const { data, lang } = props;

  const [opened, setOpened] = React.useState(false);
  const toggleOpened = () => {
    setOpened(v => !v);
  };

  const desktop = useMediaQuery('(min-width: 813px)');

  const textStyle = styleToObjResponsive(data.text.style, data.text.style_desktop, desktop);
  if (opened) textStyle.maxHeight = "100000px";

  let iconStyle = {};
  if (data.iconStyle) {
    iconStyle = styleToObjResponsive(data.iconStyle.style, data.iconStyle.style_desktop, desktop);
  }

  return (
    <div className={`component-terms_and_conditions_1 component`}
      style={styleToObjResponsive(data.style, data.style_desktop, desktop)}
    >
      <button className={`collapse ${opened ? 'active' : ''}`} onClick={toggleOpened}>
        <IconPlus src={IconPlus} className="Open" style={iconStyle} />
        <IconMinus src={IconMinus} className="Close" style={iconStyle} />
        <p style={styleToObjResponsive(data.title.style, data.title.style_desktop, desktop)}>{getText(data, `title.text.${lang}`, "")}</p>
      </button>
      <div className="tcContent rich-text" style={textStyle} dangerouslySetInnerHTML={{ __html: getText(data, `text.text.${lang}`, "") }}></div>
    </div>
  );
}

export default TermsAndConditionsComponent;