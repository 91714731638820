import { appConstants } from "../constants";
import { produce } from "immer";

const initialState = {
  landingPages: {}
};

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case appConstants.INITIALIZE:
        break;
      case appConstants.RECEIVED_CONFIG:
        if (action && action.config && action.config.landingPages) {
          draft.landingPages = action.config.landingPages;
        }
        break;
      default:
        break;
    }
  });

export default appReducer;